import moment from 'moment';
import {BaseModel} from 'sjs-base-model';
import {stringToMoment} from '../core/dataTransformer';

export default class VehicleFolder extends BaseModel {
    vehicleId = '';
    referenceSeller = '';
    createdDate = '';
    deliveryDate = '';
    storageParc = '';
    status = '';
    subStatus = '';
    plateNumber = '';
    type = '';
    brand = '';
    commercialType = '';
    reservePrice = '';
    ownerDebtor = '';
    externalMemo = '';
    secondToLastReservePrice = '';
    originalPriceArgus = '';
    numberOfPassage = '';
    vehicleDaysAmount = '';
    countNumber = '';
    repairCosts = '';
    missingSpareWheel = '';
    hasKey = '';
    hasBatteryProblem = '';
    baseObject = {};

    constructor(data, listType = null) {
        super();
        this.update(data, listType);
    }

    update(data, listType = null) {
        super.update(data);
        this.baseObject = data;
        this.cotePerso = parseInt(data['cotePerso']);
        this.estimationPrice = data['prixEstimation'];
        this.vehicleId = data['docEntryVH'];
        this.referenceSeller = data['dossierRefVendeur'];
        this.createdDate = moment(data['dateCreation'], 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.storageParc = data['parcStockageVille'];
        this.status = data['statut'];
        this.subStatus = data['sousStatut'];
        this.greyCardStatus = data['statutScanCG'];
        this.plateNumber = data['immatriculation'];
        this.deliveryDate = data['dateLivraison'] !== '' ? moment(data['dateLivraison'], 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        this.type = data['genre'];
        this.brand = data['marque'];
        this.commercialType = data['typeComm'];
        this.reservePrice = data['prixReserve'];
        this.ownerDebtor = data['proprietaireDebiteur'];
        this.externalMemo = data['memoExterne'];
        this.saleStatus = data['etatVente'];
        this.saleDate = data['dateVenteFR'] ? moment(data['dateVenteFR'], 'DD/MM/YYYY').format('DD/MM/YYYY') : '';
        this.lastCotation = data['dateDerniereCotation'] ? moment(data['dateDerniereCotation'], 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY') : '';
        this.carDoors = data['nbPortes'];
        this.warehouse = data['whsName'];
        this.status = data['statut'];
        this.subStatus = data['sousStatut'];
        this.docNum = data['dossierRefInt'];
        this.ownerDebtor = data['proprietaireDebiteur'];
        this.visualRating = data['noteVisuelle'];
        this.interiorRating = data['noteInterieur'];
        this.expertiseRating = data['noteExpertise'];
        this.vehicleAge = data['ageVehicule'];
        this.cardNameSeller = data['cardNameVendeur'];
        this.storageParkCity = data['parcStockageVille'];
        this.dateMission = data['dateMission'];
        this.argusModel = data['modeleArgus'];
        this.mineType = data['typeMine'];
        this.serialNumber = data['numeroDeSerie'];
        this.carBody = data['carrosserie'];
        this.seats = data['places'];
        this.manufactureYear = data['anneeMillesime'];
        this.energy = data['energie'];
        this.power = data['puissance'];
        this.statementKilometers = data['kilomDeclare'];
        this.drivingSchool = data['autoEcole'];
        this.color = data['couleur'];
        this.statusTechnicalControl = data['statutControleTechniqueLabel'];
        this.damage = data['avaries'] ?? '';
        this.maintenanceFollowUp = data['suiviEntretien'];
        this.state = data['etat'];
        this.hasMaintencanceBook = data['hasCE'];
        this.sellerObservation = data['observationVendeur'];
        this.plateNumberCertificate = data['certifImmatriculation'];
        this.plateNumberCertificateFollowUp = data['statutCarteGriseLabel'];
        this.coteKilo = parseInt(data['coteKilo']);
        this.urlSE = data['urlSE'];
        this.urlCT = data['urlCT'];
        this.urlFE = data['urlFE'];
        this.orderNumber = data['numeroOrdre'] ?? '';
        this.orderNumberComplementary = data['numeroOrdreComplement'] ?? '';
        // remove seconds
        this.saleHour = data['venteHeure'] ? data['venteHeure'].substr(0, 5) : '';
        this.dateReceiptGreyCard = data['dateReceptionCarteGrise'] ? moment(data['dateReceptionCarteGrise'], 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        this.originalPriceArgus = data['prixNeufArgus'];
        this.secondToLastReservePrice = data['avantDerniereReserve'];
        this.numberOfPassage = data['nombrePassage'];
        this.tvaRecoverable = data['tVARecuperable'];
        this.adjudicationPrice = data['prixAdjudication'];
        this.revocationPrice = data['prixRetrait'];
        this.vehicleDaysAmount = data['nbJoursVehicule'];
        this.reservePriceMinusOne = data['reserveMoins1'];
        this.personalQuotingEstimationRatio = data['ratioEstCP'];
        this.personalQuotingReserveRatio = data['ratioResCP'];
        this.mileageQuotingReserveRatio = data['ratioResCK'];
        this.registeredAt = moment(data['date1MEC'], 'YYYY-MM-DD');
        this.actualMileage = data['kilomReel'];
        this.hasTechnicalInspection = data['carteGriseRecue'];
        this.personalQuotingAdjudicationRatio = data['ratioAdjCP'];
        this.mileageQuotingAdjudicationRatio = data['ratioAdjCK'];
        this.repairCosts = data['fraisRemiseEnEtat'];
        this.missingSpareWheel = data['roueDeSecours'];
        this.hasKey = data['clef'];
        this.hasBatteryProblem = data['problemeBatterie'];
        this.statutScanSE = data['hasSE'];
        this.hasFE = data['hasFE'];

        // Need moment obj to sort data in DataTables
        this.deliveredAtMoment = stringToMoment(data['dateLivraison'], 'YYYY-MM-DD');
        this.createdAtMoment = stringToMoment(data['dateCreation'], 'YYYY-MM-DD');
        this.saleAtMoment = stringToMoment(data['dateVenteFR'], 'YYYY-MM-DD');

        this.excelExport = {};

        if (listType === 'vehiclesStock') {
            this.excelExport = {
                'Ref.': this.referenceSeller,
                'Nb. jour': this.getDaysFromCreation(),
                'Immat.': this.plateNumber,
                'Genre': this.type,
                'Marque': this.brand,
                'Type comm.': this.commercialType,
                'Propriet./Deb.': this.ownerDebtor,
                'Date creation': this.createdDate,
                'Date livraison': this.deliveryDate,
                'Parc stockage': this.storageParkCity,
                'Statut': this.status,
                'Sous statut': this.subStatus,
                'Réception CG': this.greyCardStatus,
                'SE': this.statutScanSE,
                'FE': this.hasFE,
            }
        }
    }

    getDaysFromCreation() {
        let now = moment(),
            createdDate = moment(this.createdDate, 'DD/MM/YYYY');

        return now.diff(createdDate, 'days');
    }
}

